import { Inject, Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import * as Services from '@shared/core/services';

import { Observable, of, timer } from 'rxjs';
import { audit, auditTime, filter, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class CartEffects {
    @Effect() public clearCartOnAccountDeletion$: Observable<Action> = this._actions$.pipe(
        ofType(actions.MemberDeleteProfileRequest),
        switchMap(() => [actions.CartReset()]),
    );

    @Effect() public onCollectionTypeSetUpdateCart$: Observable<Action> = this._actions$.pipe(
        ofType(actions.SetCollectionType),
        withLatestFrom(
            this._store.pipe(select(selectors.getCart)),
            this._store.pipe(select(selectors.getCurrentLocationNo)),
            this._store.pipe(select(selectors.getSelectedOrderType)),
            this._store.pipe(select(selectors.isCurrentRouteCheckoutPage)),
        ),
        filter(([, cart, currentLocation]) => !!cart?.locationNo === true && currentLocation !== null),
        switchMap(([action, cart, currentLocationNo, isCheckoutPage]) => {
            const isOnCurrentLocation: boolean = cart.locationNo === currentLocationNo;

            if (isCheckoutPage && action.previousOrderTypeId) {
                return [actions.CartSetupOrderTypeId({ orderTypeId: action.orderTypeId, previousOrderTypeId: action.previousOrderTypeId, locationNo: cart.locationNo })];
            }

            if (isOnCurrentLocation) {
                return [];
            }

            return this._store.pipe(
                select(selectors.getOnlineMenu),
                auditTime(100),
                filter((onlineMenu) => !!onlineMenu?.data !== false && onlineMenu.hasSucceeded === true),
                take(1),
                withLatestFrom(this._store.pipe(select(selectors.getCurrentPickupTime)), this._store.pipe(select(selectors.isCartEmpty))),
                switchMap(([onlineMenu, currentPickupTime, isCartEmpty]) => {
                    if (!isCartEmpty) {
                        return [];
                    }

                    return [
                        actions.CartSetLocationNo(currentLocationNo),
                        actions.CartSetPickupTime({ ...currentPickupTime }),
                        actions.CartSetOnlineMenu({ onlineMenu: onlineMenu.data }),
                        actions.CartRemoveAllItems(),
                    ];
                }),
            );
        }),
    );

    @Effect() public onTransferItemToCartValidateAndSetBasicCartProperties$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartSetup, actions.CartSetupWithMultipleItems, actions.CartSetupOrderTypeId, actions.CartPickupTimeUpdate),
        switchMap((action) => {
            const virtualLocationNo = action.type === actions.CartSetup.type && action?.virtualLocationNo;

            return this._store.pipe(
                select(selectors.getAllModals),
                take(1),
                withLatestFrom(
                    this._store.pipe(select(selectors.getCart)),
                    this._store.pipe(select(selectors.getCurrentLocationNo)),
                    this._store.pipe(select(selectors.getCurrentPickupTime)),
                    this._store.pipe(select(selectors.getCollectionType)),
                    this._store.pipe(select(selectors.getOnlineMenuData)),
                    this._store.pipe(select(selectors.getMenuFlowDetailsByWizzard)),
                    this._store.pipe(select(selectors.getWizzardMenuFlow)),
                    this._store.pipe(select(selectors.isCurrentRouteCheckoutPage)),
                    this._store.pipe(select(selectors.getVirtualLocationsForPhysicalLocation(action.locationNo))),
                    this._store.pipe(select(selectors.getOnlineMenuVirtualLocation(virtualLocationNo))),
                    this._store.pipe(select(selectors.getOnlineMenuVirtualLocationsData))
                ),
                switchMap(([
                    modals,
                    cart,
                    currentLocationNo,
                    currentPickupTime,
                    currentCollectionType,
                    onlineMenu,
                    menuFlowDetails,
                    wizzardMenuFlow,
                    isCheckoutPage,
                    cartActionVirtualLocations,
                    getOnlineMenuVirtualLocationsData,
                    onlineMenuVirtualLocations
                ]) => {
                    const cartIsEmpty = cart.itemsSimple.length === 0 && cart.itemsMenuFlow.length === 0;
                    const showActiveOrderDifferentOnlineLocationPrompt = () => {
                        if (!cartIsEmpty && Boolean(virtualLocationNo)) {
                            return !Utils.OnlineOrders.canOrderFromVirtualLocation(cart, cartActionVirtualLocations, virtualLocationNo);
                        }

                        return false;
                    };
                    const locationHasVirtualLocation = Boolean(virtualLocationNo) && cartActionVirtualLocations.find(virtualLocation =>
                        virtualLocation.LocationNo === virtualLocationNo);
                    const onlineMenuHasNoDataButVirtualLocation = !onlineMenu && locationHasVirtualLocation;

                    if (onlineMenuHasNoDataButVirtualLocation) {
                        onlineMenu = getOnlineMenuVirtualLocationsData.data;
                    }

                    if (action.type === actions.CartPickupTimeUpdate.type) {
                        currentPickupTime = action.pickupTime;
                    }

                    if (isCheckoutPage) {
                        onlineMenu = cart.onlineMenu;
                        onlineMenuVirtualLocations = cart.onlineMenuVirtualLocations;
                    }

                    if (action.type === actions.CartSetup.type && action.item?.MenuFlowId) {
                        /* Validate menuFlow wizzard errors first */
                        const errors: OLO.State.Wizzard.WizzardError[] = this._wizzardService.validateWizzardItems(menuFlowDetails, wizzardMenuFlow);
                        if (errors.length > 0) {
                            return of(actions.WizzardValidate({ errors }));
                        }
                    }

                    const isEditing: boolean = action.type === actions.CartSetup.type && action.item && !!action.item['_Id'] === true;
                    /* Online Virtual Location may not have physical online menu, in that case it should be checked from virtual location*/
                    const relevantCartOnlineMenu = cart.onlineMenu === null && !!cart.onlineMenuVirtualLocations?.length ? cart.onlineMenuVirtualLocations[0] : cart.onlineMenu;
                    const relevantOnlineMenu = onlineMenu === null && !!onlineMenuVirtualLocations?.length ? onlineMenuVirtualLocations[0] : onlineMenu;

                    const summary = {
                        cartIsEmpty,
                        isCartLocationNoSet: !!cart.locationNo,
                        isCartPickupTimeSet: !!cart.pickupTime,
                        isOnlineMenuTimeSet: !!relevantCartOnlineMenu && !!relevantCartOnlineMenu.StartTime && !!relevantCartOnlineMenu.EndTime,
                        isChangingOrderTypeGroup: currentCollectionType.orderTypeId
                            ? !Utils.OnlineOrders.orderTypesGroupMatch(cart.orderTypeId, currentCollectionType.orderTypeId, this._config)
                            : false,
                        isChangingInGroupOrderTypeId: currentCollectionType.orderTypeId
                            ? currentCollectionType.orderTypeId !== cart.orderTypeId
                            : false,
                        isAddingToCartFromDifferentLocation: isEditing ? false : cart.locationNo !== currentLocationNo,
                        isCurrentPickupTimeDifferentThanCartPickupTime: isEditing
                            ? false
                            : currentPickupTime == null || cart.pickupTime === null || currentPickupTime.Id !== cart.pickupTime.Id,
                        isCartOnlineMenuTimeDifferentThanCurrent: isEditing
                            ? false
                            : relevantCartOnlineMenu !== null
                                ? relevantCartOnlineMenu.StartTime !== relevantOnlineMenu.StartTime || relevantCartOnlineMenu.EndTime !== relevantOnlineMenu.EndTime
                                : true,
                        /* summary for 3 above */
                        showEmptyWarningModal: false,
                        forceCartReset: false,
                        isChangingOrderTypeScheduleModal:
                            action.type === actions.CartSetupOrderTypeId.type && action.previousOrderTypeId && action.previousOrderTypeId !== cart.orderTypeId,
                        showActiveOrderPromptFromOnlineLocation: showActiveOrderDifferentOnlineLocationPrompt()
                    };

                    /* Fix ordering when page reloads when in asap */
                    const isOrderingInASAPMode: boolean = currentPickupTime && currentPickupTime.IsAsap === true && (cart.pickupTime === null || cart.pickupTime.IsAsap === true);

                    const hasReloadedCheckoutPage = isCheckoutPage && currentPickupTime == null;

                    if (isOrderingInASAPMode || hasReloadedCheckoutPage) {
                        /* Check if difference is less then pickupTimeOrderTimeout */
                        summary.isCurrentPickupTimeDifferentThanCartPickupTime = false;
                    }
                    const dispatchActions = (...extraActions: Action[]) => {
                        const arr: Action[] = [];
                        /* #1 Cart locationNo */
                        if (!summary.isCartLocationNoSet || summary.isAddingToCartFromDifferentLocation) {
                            arr.push(actions.CartSetLocationNo(currentLocationNo));
                        }
                        /* #2 Cart PickupTime */
                        if ((!summary.isChangingOrderTypeScheduleModal && !summary.isCartPickupTimeSet) || summary.isCurrentPickupTimeDifferentThanCartPickupTime) {
                            arr.push(actions.CartSetPickupTime({ ...currentPickupTime }));
                        }
                        /* #3 OnlineMenu time */
                        if (!summary.isOnlineMenuTimeSet || summary.isCartOnlineMenuTimeDifferentThanCurrent) {
                            arr.push(actions.CartSetOnlineMenu({ onlineMenu, onlineMenuVirtualLocations }));
                        }
                        /**
                         * #4 Cart is changing orderTypeId and the group or just in the group
                         */
                        if (summary.isChangingOrderTypeGroup || summary.isChangingInGroupOrderTypeId) {
                            arr.push(actions.CartSetOrderTypeId({ orderTypeId: currentCollectionType.orderTypeId }));
                        }
                        /**
                         * #5 Schedule modal is changing orderTypeId, it use temporary orderTypeId to wait for user decistion to set to new orderType or continue order
                         */
                        if (summary.isChangingOrderTypeScheduleModal) {
                            arr.push(actions.CartSetOrderTypeId({ orderTypeId: action.type === actions.CartSetupOrderTypeId.type ? action.previousOrderTypeId : null }));
                        }

                        if (action.type === actions.CartSetup.type) {
                            arr.push(
                                actions.CartTransferItemRequest({
                                    modalId: action.modalId,
                                    locationNo: currentLocationNo,
                                    item: action.item,
                                }),
                            );
                        }

                        if (action.type === actions.CartSetupWithMultipleItems.type) {
                            const menuFlows: OLO.State.Cart.CartMenuFlow[] = action.menuFlows;
                            const simpleItems: OLO.State.Cart.CartSimpleItem[] = action.simpleItems;

                            if (menuFlows && menuFlows.length) {
                                menuFlows.forEach((menuFlow) => {
                                    arr.push(
                                        actions.CartMenuFlowAddWithMerge({
                                            locationNo: currentLocationNo,
                                            item: {
                                                ...Utils.Items.convertToSanitizedMenuFlowItem(menuFlow),
                                            },
                                        }),
                                    );
                                });
                            }

                            if (simpleItems && simpleItems.length) {
                                simpleItems.forEach((simpleItem) => {
                                    arr.push(
                                        actions.CartSimpleItemAdd({
                                            locationNo: currentLocationNo,
                                            item: {
                                                ...Utils.Items.convertToSanitizedSimpleItem(simpleItem),
                                            },
                                        }),
                                    );
                                });
                            }
                        }

                        if (summary.forceCartReset) {
                            arr.unshift(actions.CartRemoveAllItems());
                        }

                        return extraActions ? arr.concat(extraActions) : arr;
                    };

                    if (!summary.cartIsEmpty) {
                        summary.forceCartReset =
                            summary.isAddingToCartFromDifferentLocation ||
                            summary.isCurrentPickupTimeDifferentThanCartPickupTime ||
                            summary.isCartOnlineMenuTimeDifferentThanCurrent ||
                            summary.isChangingOrderTypeGroup ||
                            summary.isChangingOrderTypeScheduleModal ||
                            summary.showActiveOrderPromptFromOnlineLocation;
                    }

                    summary.showEmptyWarningModal = summary.forceCartReset === true && summary.cartIsEmpty === false;

                    if (!summary.showEmptyWarningModal) {
                        if (action.type === actions.CartSetupWithMultipleItems.type) {
                            this._modalsService.close(action.modalId);
                        }

                        return dispatchActions();
                    }

                    /* Show active prompt modal for different physical location or different online location inside phyisical location */
                    let modalParams: OLO.State.Modals.Modal;

                    if (summary.showActiveOrderPromptFromOnlineLocation) {
                        modalParams = {
                            type: 'active-order-different-online-location-prompt',
                            animate: null,
                            virtualLocationNo: action.type === actions.CartSetup.type ? action?.virtualLocationNo : null
                        };
                    } else {
                        modalParams = {
                            type: 'active-order-prompt',
                            animate: null,
                        };
                    }

                    const modalId = 'modalId' in action ? action.modalId : null;
                    const modalIdExists = modalId && modalId !== OLO.Components.Modals.MODAL_ID_TYPE.FORCE_NEW_MODAL;

                    if (modalIdExists && !modals.some((modal) => modal.type === 'schedule')) {
                        this._modalsService.swap(modalId, modalParams);
                    } else if (!modals.some((modal) => modal.type === 'active-order-prompt')) {
                        this._modalsService.show(modalParams);
                    }

                    return this._actions$.pipe(
                        ofType(actions.CartActiveOrderContinue, actions.CartActiveOrderStartNew),
                        take(1),
                        switchMap((activeOrderDecision) => {
                            if (activeOrderDecision.type === actions.CartActiveOrderContinue.type) {
                                return [
                                    actions.PatchOrderTypeIdCollectionTypeValue({
                                        orderTypeId: cart.orderTypeId || null,
                                    }),
                                    actions.CurrentLocationPickupTimeSet({ ...cart.pickupTime }),
                                ];
                            }
                            if (activeOrderDecision.type === actions.CartActiveOrderStartNew.type) {
                                return dispatchActions(
                                    actions.SetCollectionType({
                                        orderTypeId: action.type === actions.CartSetupOrderTypeId.type ? action.previousOrderTypeId : currentCollectionType.orderTypeId,
                                        address: null,
                                        tableNo: null,
                                    }),
                                    actions.CartSetPickupTime({ ...currentPickupTime }),
                                );
                            }
                        }),
                    );
                }),
            );
        }),
    );

    @Effect() public addItemToCartAndCheckForAnyUpsells$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartTransferItemRequest),
        switchMap((action) =>
            this._store.pipe(
                select(selectors.getModalById(action.modalId)),
                take(1),
                withLatestFrom(this._store.select(selectors.getMenuFlowDetailsByWizzard), this._store.select(selectors.getWizzardMenuFlow)),
                switchMap(([modal, menuFlow, fromWizzardMenuFlow]) => {
                    const isMenuFlow: boolean = !!action.item.MenuFlowId;
                    if (!isMenuFlow) {
                        const isEditing: boolean = Number.isInteger(action.item._Id);
                        if (modal) this._modalsService.close(action.modalId);

                        if (!isEditing) {
                            const productWithId = {
                                ...action.item,
                            } as OLO.State.Cart.CartSimpleItem;
                            productWithId._Id = new Date().getTime();

                            return [
                                actions.CartSimpleItemAdd({ locationNo: action.locationNo, item: Utils.Items.convertToSanitizedSimpleItem(productWithId) }),
                                actions.WizzardUnmountAll(),
                            ];
                        }

                        return [
                            actions.CartSimpleItemUpdate({ item: Utils.Items.convertToSanitizedSimpleItem(action.item as OLO.State.Cart.CartSimpleItem) }),
                            actions.WizzardUnmountAll(),
                        ];
                    }
                    const bundleActions: Action[] = [];
                    const wizzardMenuFlow: OLO.State.Wizzard.WizzardMenuFlow = fromWizzardMenuFlow as OLO.State.Wizzard.WizzardMenuFlow;
                    const menuFlowDetails: OLO.DTO.MenuFlowDetailsModel = menuFlow as OLO.DTO.MenuFlowDetailsModel;
                    const isNew: boolean = wizzardMenuFlow._Id === null;

                    const errors: OLO.State.Wizzard.WizzardError[] = this._wizzardService.validateWizzardItems(menuFlow, wizzardMenuFlow);
                    if (errors.length > 0) {
                        return of(actions.WizzardValidate({ errors }));
                    }

                    if (isNew) {
                        wizzardMenuFlow._Id = new Date().getTime();
                        bundleActions.push(
                            actions.CartMenuFlowAddWithMerge({
                                locationNo: wizzardMenuFlow.LocationNo,
                                item: {
                                    _Id: new Date().getTime(),
                                    ...wizzardMenuFlow,
                                },
                            }),
                        );
                    } else {
                        bundleActions.push(actions.CartMenuFlowUpdate(wizzardMenuFlow));
                    }

                    bundleActions.push(actions.WizzardUnmountAll());

                    const showUpsellModal: boolean = isNew && Number.isInteger((menuFlowDetails as OLO.DTO.MenuFlowDetailsModel).UpsellMenuFlowId);
                    if (showUpsellModal) {
                        bundleActions.push(
                            actions.WizzardMenuFlowUpsellSetupRequest(
                                (menuFlowDetails as OLO.DTO.MenuFlowDetailsModel).UpsellMenuFlowId,
                                wizzardMenuFlow.LocationNo,
                                action.modalId,
                                wizzardMenuFlow?.VirtualLocationNo,
                            ),
                        );
                    }

                    /* And hide modal if there is no upsell to show */
                    if (action.modalId && !showUpsellModal) {
                        this._modalsService.close(action.modalId);
                    }

                    return bundleActions;
                }),
            ),
        ),
    );

    @Effect() public findDuplicatedMenuFlowAndMerge$: Observable<Action> = this._actions$ /* AOLO-282 */
        .pipe(
            ofType(actions.CartMenuFlowAddWithMerge),
            withLatestFrom(this._store.select(selectors.getCartMenuFlows)),
            switchMap(([action, cartMenuFlows]) => {
                const newItem: OLO.State.Cart.CartMenuFlowExtended = action.item;
                const foundSimilarInCart: OLO.State.Cart.CartMenuFlow = cartMenuFlows.find(
                    (obj) =>
                        obj.MenuFlowId === newItem.MenuFlowId &&
                        obj.PosDescription === newItem.PosDescription &&
                        obj.PosDisplay === newItem.PosDisplay &&
                        obj.SpecialInstructions === newItem.SpecialInstructions &&
                        obj.UnitPrice === newItem.UnitPrice &&
                        obj.VirtualLocationNo === newItem.VirtualLocationNo
                );

                return of(foundSimilarInCart).pipe(
                    take(1),
                    switchMap(() => {
                        if (!foundSimilarInCart) return of(false);
                        let isSimilar: boolean = true;

                        if (foundSimilarInCart.Pages.length !== newItem.Pages.length) {
                            return of((isSimilar = false));
                        }
                        foundSimilarInCart.Pages.forEach((Page) => {
                            /* Check if all pages have same PageIdentifier and products QTY prop */
                            const foundPage = newItem.Pages.find((obj) => obj.PageIdentifier === Page.PageIdentifier);
                            if (!foundPage || foundPage.Products.length !== Page.Products.length) {
                                return (isSimilar = false);
                            }

                            /* Check products */
                            Page.Products.forEach((Product) => {
                                const foundProduct = foundPage.Products.find(
                                    (obj) =>
                                        obj.ProductId === Product.ProductId &&
                                        obj.Quantity === Product.Quantity &&
                                        obj.OriginalPrice === Product.OriginalPrice &&
                                        obj.OverridedPrice === Product.OverridedPrice &&
                                        obj.PageProductIdentifier === Product.PageProductIdentifier &&
                                        obj.Plu === Product.Plu &&
                                        obj.ProductName === Product.ProductName &&
                                        obj.ProductDescription === Product.ProductDescription,
                                );

                                if (!foundProduct) {
                                    return (isSimilar = false);
                                }

                                /* Check IngredientsAdded */
                                const foundIngredients = foundProduct.IngredientsChanges ? foundProduct.IngredientsChanges : null;
                                const productIngredients = Product.IngredientsChanges ? Product.IngredientsChanges : null;
                                if (foundIngredients === null && productIngredients === null) {
                                    return;
                                }
                                if (typeof foundIngredients !== typeof productIngredients) {
                                    return (isSimilar = false);
                                }

                                if (foundProduct.IngredientsChanges.IngredientsAdded.length !== Product.IngredientsChanges.IngredientsAdded.length) {
                                    return (isSimilar = false);
                                }
                                Product.IngredientsChanges.IngredientsAdded.forEach((ingredient) => {
                                    const foundIngredient = foundProduct.IngredientsChanges.IngredientsAdded.find(
                                        (obj) =>
                                            obj.ExtraPrice === ingredient.ExtraPrice &&
                                            obj.IngredientPLU === ingredient.IngredientPLU &&
                                            obj.ModifierID === ingredient.ModifierID &&
                                            obj.IngredientPLU === ingredient.IngredientPLU,
                                    );
                                    if (!foundIngredient) {
                                        return (isSimilar = false);
                                    }
                                });

                                /* Check IngredientsModified */
                                if (foundProduct.IngredientsChanges.IngredientsModified.length !== Product.IngredientsChanges.IngredientsModified.length) {
                                    return (isSimilar = false);
                                }
                                Product.IngredientsChanges.IngredientsModified.forEach((ingredient) => {
                                    const foundIngredient = foundProduct.IngredientsChanges.IngredientsModified.find(
                                        (obj) =>
                                            obj.ExtraPrice === ingredient.ExtraPrice &&
                                            obj.IngredientPLU === ingredient.IngredientPLU &&
                                            obj.ModifierID === ingredient.ModifierID &&
                                            obj.IngredientPLU === ingredient.IngredientPLU,
                                    );
                                    if (!foundIngredient) {
                                        return (isSimilar = false);
                                    }
                                });

                                /* Check IngredientsRemoved */
                                if (foundProduct.IngredientsChanges.IngredientsRemoved.length !== Product.IngredientsChanges.IngredientsRemoved.length) {
                                    return (isSimilar = false);
                                }
                                Product.IngredientsChanges.IngredientsRemoved.forEach((ingredient) => {
                                    const foundIngredient = foundProduct.IngredientsChanges.IngredientsRemoved.find(
                                        (obj) => obj.IngredientPLU === ingredient.IngredientPLU && obj.ID === ingredient.ID && obj.IngredientPLU === ingredient.IngredientPLU,
                                    );
                                    if (!foundIngredient) {
                                        return (isSimilar = false);
                                    }
                                });

                                /* Check IngredientsRemoved */
                                if (foundProduct.IngredientsChanges.IngredientsSwapped.length !== Product.IngredientsChanges.IngredientsSwapped.length) {
                                    return (isSimilar = false);
                                }
                                Product.IngredientsChanges.IngredientsSwapped.forEach((ingredient) => {
                                    const foundIngredient = foundProduct.IngredientsChanges.IngredientsSwapped.find(
                                        (obj) =>
                                            obj.ExtraPrice === ingredient.ExtraPrice &&
                                            obj.ID === ingredient.ID &&
                                            obj.ModifierID === ingredient.ModifierID &&
                                            obj.ModifierName === ingredient.ModifierName &&
                                            obj.NewIngredientPLU === ingredient.NewIngredientPLU &&
                                            obj.OldIngredientPLU === ingredient.OldIngredientPLU &&
                                            obj.OrderItemId === obj.OrderItemId,
                                    );
                                    if (!foundIngredient) {
                                        return (isSimilar = false);
                                    }
                                });
                            });
                        });

                        return of(isSimilar);
                    }),
                    switchMap((isSimilar) => {
                        if (!isSimilar) {
                            const markedHidden = {
                                ...newItem,
                                Pages: newItem.Pages.map((page) => {
                                    if (page.HideFromKiosk === true) {
                                        return {
                                            ...page,
                                            Products: page.Products.map((product) => ({
                                                ...product,
                                                _HideFromKiosk: true,
                                            })),
                                        };
                                    }

                                    return page;
                                }),
                            };

                            return of(actions.CartMenuFlowAdd({ locationNo: action.locationNo, item: markedHidden }));
                        }

                        return this._store.pipe(
                            select(selectors.isDeviceMobile),
                            take(1),
                            switchMap((isMobile) => {
                                if (!isMobile) {
                                    setTimeout(() => {
                                        this._cartService.showPopup();
                                    }, 100);
                                }

                                return of(
                                    actions.CartMenuFlowIncrement({ changeValue: newItem.Quantity, context: OLO.Enums.CART_ACTION_CONTEXT.OUTSIDE_CART, item: foundSimilarInCart }),
                                );
                            }),
                        );
                    }),
                );
            }),
        );

    @Effect() public closeActiveOrderPromptWhenUsingReorder$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartSimpleItemAdd, actions.CartMenuFlowAdd),
        audit(() => timer(100)),
        withLatestFrom(
            this._store.pipe(select(selectors.getAllModals)),
            this._store.pipe(select(selectors.isDeviceMobile)),
            this._store.pipe(select(selectors.isCartEmpty)),
            (action, modals, isMobile, isCartEmpty) => {
                //
                //  Reorder case with Active order prompt
                //  Reordering items ommit upsell checks, where modals are controlled, so they won't get closed.
                //  I know this is a bit robust, but when doing things other way around - starting project from the middle, this is
                //  easier than refactoring complicated flow.
                //  This should be handled better, but for now, let's just pretend it works
                //
                let modal: OLO.State.Modals.Modal;
                if (action.item._IsReorder) {
                    /* We've got item from reorder here - should close modal now */
                    modal = modals.find((obj) => obj.type === 'active-order-prompt');
                }

                return [action, modal, isMobile, isCartEmpty];
            },
        ),
        switchMap(([, modal, isMobile, isCartEmpty]) => {
            if (modal) {
                this._modalsService.close((modal as OLO.State.Modals.Modal).id);
            }

            if (!isMobile && !isCartEmpty) {
                this._cartService.showPopup();
            }

            return [];
        }),
    );

    @Effect() public setupWizzardOnCartItemEdit$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartEditItem),
        withLatestFrom(this._store.pipe(select(selectors.getCart)), (action, cart: OLO.State.Cart) => [action.item, cart.locationNo]),
        switchMap(([item, locationNo]: [OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem, number]) =>
            of(actions.WizzardSetupItem(locationNo, item, null, item?.VirtualLocationNo))
        ),
    );

    @Effect() public setupWizzardOnCartAddVoucher$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartAddVoucher),
        tap(() => this._store.dispatch(actions.OnlineOrderClearVoucherRequest())),
        withLatestFrom(this._store.pipe(select(selectors.getCart)), (action, cart: OLO.State.Cart) => [action, cart.locationNo]),
        switchMap(([, locationNo]) => of(actions.WizzardAddVoucher(locationNo as number))),
    );

    @Effect({ dispatch: false }) public saveCartContentsInStorage$: Observable<any> = this._actions$.pipe(
        ofType(
            actions.CartReset,
            actions.CartRemoveAllItems,
            actions.CartMenuFlowAdd,
            actions.CartMenuFlowDecrement,
            actions.CartMenuFlowIncrement,
            actions.CartMenuFlowChangeQuantity,
            actions.CartMenuFlowRemove,
            actions.CartMenuFlowUpdate,
            actions.CartSimpleItemAdd,
            actions.CartSimpleItemDecrement,
            actions.CartSimpleItemIncrement,
            actions.CartSimpleItemChangeQuantity,
            actions.CartSimpleItemRemove,
            actions.CartSimpleItemUpdate,
            actions.CartAddActivatedVoucher,
            actions.CartRemoveActivatedVoucher,
        ),
        auditTime(500),
        withLatestFrom(this._store.select(selectors.getCart)),
        switchMap(([action, cart]) => {
            if (action.type === actions.CartReset.type) {
                Utils.Storage.remove(OLO.Enums.CART_STORAGE.DATA);
            } else {
                Utils.Storage.set(OLO.Enums.CART_STORAGE.DATA, this._cryptoService.encrypt(JSON.stringify(cart)));
            }

            return [];
        }),
    );

    @Effect({ dispatch: false }) public removeCartDataFromStorage$: Observable<any> = this._actions$.pipe(
        ofType(actions.CartReset),
        switchMap(() => {
            Utils.Storage.remove(OLO.Enums.CART_STORAGE.DATA as unknown as string);

            return [];
        }),
    );

    @Effect() public recalculateCartContents$: Observable<Action> = this._actions$.pipe(
        ofType(
            actions.CartRemoveAllItems,
            actions.CartMenuFlowAdd,
            actions.CartMenuFlowDecrement,
            actions.CartMenuFlowIncrement,
            actions.CartMenuFlowChangeQuantity,
            actions.CartMenuFlowRemove,
            actions.CartMenuFlowUpdate,
            actions.CartSimpleItemAdd,
            actions.CartSimpleItemDecrement,
            actions.CartSimpleItemChangeQuantity,
            actions.CartSimpleItemIncrement,
            actions.CartSimpleItemRemove,
            actions.CartSimpleItemUpdate,
        ),
        withLatestFrom(this._store.pipe(select(selectors.isCurrentRouteCheckoutPage))),
        switchMap(([, isCheckout]) => {
            if (isCheckout) return of(actions.OnlineOrderRecalculateRequest());

            return [];
        }),
    );

    @Effect() public resetCartOnLastItemRemoved$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartMenuFlowRemove, actions.CartSimpleItemRemove),
        withLatestFrom(this._store.pipe(select(selectors.getCart))),
        switchMap(([, cart]) => {
            const productsTotal: number = cart.itemsSimple ? cart.itemsSimple.length : 0;
            const menuFlowsTotal: number = cart.itemsMenuFlow ? cart.itemsMenuFlow.length : 0;

            if (productsTotal || menuFlowsTotal) return [];

            return of(actions.CartReset());
        }),
    );

    @Effect() public resetVoucherOnCartRemoveAllItems$: Observable<Action> = this._actions$.pipe(
        ofType(actions.CartRemoveAllItems),
        withLatestFrom(this._store.select(selectors.getActiveVoucher)),
        switchMap(([, voucher]) => {
            if (voucher) {
                return of(actions.OnlineOrderRemoveVoucherRequest());
            } else {
                return [];
            }
        }),
    );

    @Effect() public onLocationsFiltersSetAddress$: Observable<Action> = this._actions$.pipe(
        ofType(actions.LocationsFiltersSetAddress),
        switchMap((address) => [actions.CartSetDeliveryAddress(address)]),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _cryptoService: Services.CryptoService,
        private _modalsService: Services.ModalsService,
        private _wizzardService: Services.WizzardService,
        private _cartService: Services.CartService,
    ) {}
}
