import { createAction, props } from '@ngrx/store';

export const WizzardCancel = createAction(
    '[Wizzard] Cancel',
    props<{ modalId: number; }>(),
);

export const WizzardValidate = createAction(
    '[Wizzard] Validate',
    props<{ errors: OLO.State.Wizzard.WizzardError[]; }>(),
);

/* Setting up wizzard */
export const WizzardSetupItem = createAction(
    '[Wizzard] Setup item in wizzard state',
    (
        locationNo: number,
        item: OLO.State.Cart.CartMenuFlow
        | OLO.State.Cart.CartSimpleItem
        | OLO.DTO.OnlineMenuProductResponseModel,
        modalId: number = null,
        virtualLocationNo: number = null
    ) => ({ locationNo, item, modalId, virtualLocationNo }),
);

export const WizzardSilentSetupItem = createAction(
    '[Wizzard] Setup item in wizzard state - add as is',
    (locationNo: number, item: OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem | OLO.DTO.OnlineMenuProductResponseModel,
        virtualLocationNo?: number) => ({ locationNo, item, virtualLocationNo }),
);

export const WizzardStepMenuFlowInit = createAction(
    '[Wizzard] Step menu flow init',
    (
        locationNo: number,
        item: OLO.State.Cart.CartMenuFlow
        | OLO.State.Cart.CartSimpleItem
        | OLO.DTO.OnlineMenuProductResponseModel,
        modalId: number = null,
        virtualLocationNo: number = null
    ) => ({ locationNo, item, modalId, virtualLocationNo }),
);

export const WizzardStepMenuFlowRequestData = createAction(
    '[Wizzard] Step menu flow request data',
    props<{ locationNo: number; item: OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem | OLO.DTO.OnlineMenuProductResponseModel; modalId: number;
        virtualLocationNo?: number; }>(),
);

export const WizzardStepMenuFlowActivateProducts = createAction(
    '[Wizzard] Step menu flow activate products',
    props<{ locationNo: number; item: OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem | OLO.DTO.OnlineMenuProductResponseModel;
        modalId: number; virtualLocationNo?: number; }>(),
);

export const WizzardStepMenuFlowMount = createAction(
    '[Wizzard] Step menu flow mount',
    props<{ locationNo: number; menuFlowId: number; item: OLO.State.Wizzard.WizzardMenuFlow; modalId: number; }>(),
);

export const WizzardStepSimpleItemMount = createAction(
    '[Wizzard] Step simple item mount',
    (item: OLO.State.Wizzard.WizzardSimpleItem, modalId: number = null) => ({ item, modalId }),
);

export const WizzardMountMenuFlowUpsell = createAction(
    '[Wizzard] Menu flow upsell mount',
    props<{ item: OLO.State.Wizzard.WizzardMenuFlow; modalId: number; }>(),
);

export const WizzardUnmountAll = createAction('[Wizzard] Unmount simple item and menu flows and clear all errors');

export const WizzardSimpleItemChangeQuantity = createAction('[Wizzard] Change quantity of simple item', (item: OLO.State.Wizzard.WizzardSimpleItem, changeValue: number) => ({
    item,
    changeValue,
}));

/* Setting up wizzard end */
export const WizzardSimpleItemIncrement = createAction(
    '[Wizzard] Increment simple item',
    (item: OLO.State.Wizzard.WizzardSimpleItem, changeValue: number = 1, ) => ({ item, changeValue })
);

export const WizzardSimpleItemDecrement = createAction(
    '[Wizzard] Decrement simple item',
    (item: OLO.State.Wizzard.WizzardSimpleItem, changeValue: number = 1, ) => ({ item, changeValue })
);

export const WizzardSimpleItemSpecialInstructions = createAction(
    '[Wizzard] Special instructions for simple item',
    (item: OLO.State.Wizzard.WizzardSimpleItem, specialInstruction: string = null) => ({ item, specialInstruction }),
);

export const WizzardMenuFlowReplaceAllWithNew = createAction(
    '[Wizzard] Replace all products on specific page with new product',
    props<{ pageIdentifier: number; product: OLO.State.Wizzard.WizzardMenuFlowItem; }>(),
);

export const WizzardRecalculatePricesForMenuFlowRequest = createAction(
    '[Wizzard] Request Recalculate prices for menu flow',
    props<{ menuFlowDetails: OLO.DTO.MenuFlowDetailsModel; wizzardMenuFlowDetails: OLO.State.Wizzard.WizzardMenuFlow; }>(),
);

export const WizzardRecalculatePricesForMenuFlowSuccessRequest = createAction(
    '[Wizzard] Success request recalculate prices for menu flow',
    props<{ menuFlowDetails: OLO.DTO.MenuFlowDetailsModel; wizzardMenuFlowDetails: OLO.State.Wizzard.WizzardMenuFlow; priceObj: OLO.Ordering.PricingMenuFlow; }>(),
);

export const WizzardRecalculatePricesForMenuFlowErrorRequest = createAction(
    '[Wizzard] Error request recalculate prices for menu flow',
    props<{ menuFlowDetails: OLO.DTO.MenuFlowDetailsModel; wizzardMenuFlowDetails: OLO.State.Wizzard.WizzardMenuFlow; ex?: any; }>(),
);

export const WizzardMenuFlowIncrement = createAction(
    '[Wizzard] Increment menu flow',
    (changeValue: number = 1) => ({ changeValue })
);

export const WizzardMenuFlowDecrement = createAction(
    '[Wizzard] Decrement menu flow',
    (changeValue: number = 1) => ({ changeValue })
);

export const WizzardMenuFlowChangeQuantity = createAction('[Wizzard] Change quantity of menu flow', (changeValue: number) => ({ changeValue }));

export const WizzardMenuFlowSpecialInstructions = createAction(
    '[Wizzard] Special instructions menu flow',
    (specialInstructions: string = null) => ({ specialInstructions })
);


/* Menu flow single items */
export const WizzardMenuFlowAddProduct = createAction(
    '[Wizzard] Add PRODUCT to specific page',
    props<{ pageIdentifier: number; product: OLO.State.Wizzard.WizzardMenuFlowItem; }>(),
);

export const WizzardMenuFlowRemoveProduct = createAction(
    '[Wizzard] Remove PRODUCT to specific page',
    props<{ pageIdentifier: number; productId: number; }>(),
);

export const WizzardMenuFlowProductDecrement = createAction(
    '[Wizzard] Decrement PRODUCT in specific page',
    (pageIdentifier: number, productId: number, changeValue: number = 1) => ({ pageIdentifier, productId, changeValue }),
);

export const WizzardMenuFlowProductIncrement = createAction(
    '[Wizzard] Increment PRODUCT in specific page',
    (pageIdentifier: number, productId: number, changeValue: number = 1) => ({ pageIdentifier, productId, changeValue }),
);

export const WizzardMenuFlowProductChangeQuantity = createAction(
    '[Wizzard] Change quantity of PRODUCT in specific page',
    (pageIdentifier: number, productId: number, changeValue: number = 1) => ({ pageIdentifier, productId, changeValue }),
);

/* Menu flow modifiers */
export const WizzardMenuFlowSelectModifier = createAction(
    '[Wizzard] Select modifier',
    props<{ modifier: APICommon.IngredientModifierExtended; pageIdentifier: number; productId: number; }>(),
);

/* Upsell */
export const WizzardMenuFlowUpsellSetupRequest = createAction(
    '[Wizzard] Request upsell setup',
    (menuFlowId: number, locationNo: number, modalId: number = new Date().getTime(), virtualLocationNo: number = null) => ({ menuFlowId, locationNo, modalId, virtualLocationNo }),
);

/* Setting up wizzard */
export const WizzardAddVoucher = createAction(
    '[Wizzard] Add voucher',
    (locationNo: number, modalId: number = null) => ({ locationNo, modalId }),
);
