import * as Utils from '@shared/core/utils';

export class LocationsMapper {
    public static mapGetLocationsWithOnlineOrdering(
        locationsWithOnlineOrdering: APIv3.TerminalsGetOnlineOrderingLocations.Responses.$200,
    ): OLO.DTO.OnlineOrderingLocationBusinessModel[] {
        return locationsWithOnlineOrdering
        // TODO: revert it to APIv3.OnlineOrderingLocationBusinessModel in line below
            ? locationsWithOnlineOrdering?.map((location: APIv3_SNAPSHOT.OnlineOrderingLocationBusinessModel) => ({
                LocationNo: location.LocationNo,
                VenueNo: location.VenueNo,
                LocationDescription: location.LocationDescription,
                StreetAddress: location.StreetAddress,
                LocationOLOIsActive: location.LocationOLOIsActive,
                EOIPriceLevelID: location.EOIPriceLevelID,
                LocationCode: location.LocationCode,
                LocationEmail: location.LocationEmail,
                LocationPhone: location.LocationPhone,
                GLCode: location.GLCode,
                VenueGLID: location.VenueGLID,
                State: location.State,
                Country: location.Country,
                PostCode: location.PostCode,
                Latitude: location.Latitude,
                Longitude: location.Longitude,
                TimeZoneId: location.TimeZoneId,
                LocationFriendlyName: location.LocationFriendlyName,
                LocationNotes: location.LocationNotes,
                MerchantID: location.MerchantID,
                GooglePlacesID: location.GooglePlacesID,
                ExternalLocationID: location.ExternalLocationID,
                SAPCustomerID: location.SAPCustomerID,
                OnlineOrderingStatus: location.OnlineOrderingStatus,
                Suburb: location.Suburb,
                TableLayoutId: location.TableLayoutId,
                IsOpenNow: location.IsOpenNow,
                MinimumPickupTime: location.MinimumPickupTime,
                OperatingTimeInfo:
                      location.OperatingTimeInfo?.map(({ DayOfWeek, Date, OpeningTime, ClosingTime, PickupTimes }) => ({
                          DayOfWeek,
                          Date,
                          OpeningTime,
                          ClosingTime,
                          PickupTimes:
                              PickupTimes?.map((pickup) => ({
                                  From: Utils.Dates.createHoursIntFromDate(pickup.From) < Utils.Dates.createHoursIntFromDate(OpeningTime) ? OpeningTime : pickup.From,
                                  To: Utils.Dates.createHoursIntFromDate(pickup.To) > Utils.Dates.createHoursIntFromDate(ClosingTime) ? ClosingTime : pickup.To,
                                  MinimumPickupTime: pickup.MinimumPickupTime,
                              })) || null,
                      })) || null,
                OrderingTimeInfo: location.OrderingTimeInfo
                    ? location.OrderingTimeInfo.map((item: APIv3.LocationOrderingTimeInfoModel) => ({
                        DayOfWeek: item.DayOfWeek,
                        Date: item.Date,
                        OpeningTime: item.OpeningTime,
                        ClosingTime: item.ClosingTime,
                        PickupTimes: item.PickupTimes
                            ? item.PickupTimes.map((pickup: APIv3.ILocationOrderingPickupTimeInfoModel) => ({
                                From: Utils.Dates.createHoursIntFromDate(pickup.From) < Utils.Dates.createHoursIntFromDate(item.OpeningTime) ? item.OpeningTime : pickup.From,
                                To: Utils.Dates.createHoursIntFromDate(pickup.To) > Utils.Dates.createHoursIntFromDate(item.ClosingTime) ? item.ClosingTime : pickup.To,
                                MinimumPickupTime: pickup.MinimumPickupTime,
                            }))
                            : null,
                    }))
                    : null,
                VenueTags: location.VenueTags
                    ? location.VenueTags.map((item: APIv3.TerminalVenueTagModel) => ({
                        Id: item.Id,
                        TagId: item.TagId,
                        VenueNo: item.VenueNo,
                        TagName: item.TagName,
                    }))
                    : null,
                LocationTags: location.LocationTags
                    ? location.LocationTags.map((item: APIv3.TerminalLocationTagModel) => ({
                        Id: item.Id,
                        TagId: item.TagId,
                        LocationNo: item.LocationNo,
                        TagName: item.TagName,
                    }))
                    : null,
                FutureOrderingMinDaysAhead: location.FutureOrderingMinDaysAhead ?? null,
                FutureOrderingMaxDaysAhead: location.FutureOrderingMaxDaysAhead ?? null,
                DeliveryRadius: location.DeliveryRadius,
                OrderTypes: location.OrderTypes
                    ? location.OrderTypes.map((item: APIv3.TerminalLocationOrderTypeModel) => ({
                        Id: item.Id,
                        TypeDescription: item.TypeDescription,
                        /** Should use FutureOrderingMin/MaxDaysAhead */
                        IsEnabledForFutureOrdering: item.IsEnabledForFutureOrdering ?? null,
                        /**
                             * * if both min/max === null, future order is disabled
                             * * if both min/max === 0, can order for today only
                             * * if both min/max have values, can order only for those days ahead
                             */
                        FutureOrderingMinDaysAhead: item.FutureOrderingMinDaysAhead ?? null,
                        /**
                             * * if both min/max === null, future order is disabled
                             * * if both min/max === 0, can order for today only
                             * * if both min/max have values, can order only for those days ahead
                             */
                        FutureOrderingMaxDaysAhead: item.FutureOrderingMaxDaysAhead,
                        OrderingTimeInfo: item.OrderingTimeInfo?.map((orderingTimeInfo) => ({
                            DayOfWeek: orderingTimeInfo.DayOfWeek,
                            Date: orderingTimeInfo.Date,
                            OpeningTime: orderingTimeInfo.OpeningTime,
                            ClosingTime: orderingTimeInfo.ClosingTime,
                        })),
                    }))
                    : null,
                OnlineOrderConfirmationEmailNotes: location.OnlineOrderConfirmationEmailNotes,
                VirtualLocations: location.VirtualLocations ? location.VirtualLocations.map(virtualLocation => ({
                    DisplayIndex: virtualLocation.DisplayIndex,
                    AccentColour: virtualLocation.AccentColour,
                    LocationClassification: virtualLocation.LocationClassification,
                    EOIPriceLevelID: virtualLocation.EOIPriceLevelID,
                    LocationCode: virtualLocation.LocationCode,
                    LocationDescription: virtualLocation.LocationDescription,
                    LocationEmail: virtualLocation.LocationEmail,
                    LocationFriendlyName: virtualLocation.LocationFriendlyName,
                    LocationNo: virtualLocation.LocationNo,
                    LocationNotes: virtualLocation.LocationNotes,
                    LocationOLOIsActive: virtualLocation.LocationOLOIsActive,
                    LocationPhone: virtualLocation.LocationPhone,
                    LocationTags: virtualLocation.LocationTags,
                    MinimumPickupTime: virtualLocation.MinimumPickupTime,
                    OnlineOrderConfirmationEmailNotes: virtualLocation.OnlineOrderConfirmationEmailNotes,
                    OnlineOrderingStatus: virtualLocation.OnlineOrderingStatus,
                    OperatingTimeInfo:
                        virtualLocation.OperatingTimeInfo?.map(({ DayOfWeek, Date, OpeningTime, ClosingTime, PickupTimes }) => ({
                            DayOfWeek,
                            Date,
                            OpeningTime,
                            ClosingTime,
                            PickupTimes:
                              PickupTimes?.map((pickup) => ({
                                  From: Utils.Dates.createHoursIntFromDate(pickup.From) < Utils.Dates.createHoursIntFromDate(OpeningTime) ? OpeningTime : pickup.From,
                                  To: Utils.Dates.createHoursIntFromDate(pickup.To) > Utils.Dates.createHoursIntFromDate(ClosingTime) ? ClosingTime : pickup.To,
                                  MinimumPickupTime: pickup.MinimumPickupTime,
                              })) || null,
                        })) || null,
                    OrderingTimeInfo: virtualLocation.OrderingTimeInfo
                        ? virtualLocation.OrderingTimeInfo.map((item: APIv3.LocationOrderingTimeInfoModel) => ({
                            DayOfWeek: item.DayOfWeek,
                            Date: item.Date,
                            OpeningTime: item.OpeningTime,
                            ClosingTime: item.ClosingTime,
                            PickupTimes: item.PickupTimes
                                ? item.PickupTimes.map((pickup: APIv3.ILocationOrderingPickupTimeInfoModel) => ({
                                    From: Utils.Dates.createHoursIntFromDate(pickup.From) < Utils.Dates.createHoursIntFromDate(item.OpeningTime) ? item.OpeningTime : pickup.From,
                                    To: Utils.Dates.createHoursIntFromDate(pickup.To) > Utils.Dates.createHoursIntFromDate(item.ClosingTime) ? item.ClosingTime : pickup.To,
                                    MinimumPickupTime: pickup.MinimumPickupTime,
                                }))
                                : null,
                        }))
                        : null,
                    OrderTypes: virtualLocation.OrderTypes?.map((item: APIv3.TerminalLocationOrderTypeModel) => ({
                        Id: item.Id,
                        TypeDescription: item.TypeDescription,
                        /** Should use FutureOrderingMin/MaxDaysAhead */
                        IsEnabledForFutureOrdering: item.IsEnabledForFutureOrdering ?? null,
                        /**
                             * * if both min/max === null, future order is disabled
                             * * if both min/max === 0, can order for today only
                             * * if both min/max have values, can order only for those days ahead
                             */
                        FutureOrderingMinDaysAhead: item.FutureOrderingMinDaysAhead ?? null,
                        /**
                             * * if both min/max === null, future order is disabled
                             * * if both min/max === 0, can order for today only
                             * * if both min/max have values, can order only for those days ahead
                             */
                        FutureOrderingMaxDaysAhead: item.FutureOrderingMaxDaysAhead,
                        OrderingTimeInfo: item.OrderingTimeInfo?.map((orderingTimeInfo) => ({
                            DayOfWeek: orderingTimeInfo.DayOfWeek,
                            Date: orderingTimeInfo.Date,
                            OpeningTime: orderingTimeInfo.OpeningTime,
                            ClosingTime: orderingTimeInfo.ClosingTime,
                        })),
                    }))
                })) : null
            }))
            : null;
    }
}
